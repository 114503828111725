const config = {
  G_CLIENT_ID: {
    'public.ndexbio.org':
      '802839698598-8rsr8va0bd0e6lmi0ghemtrvldk9rnla.apps.googleusercontent.com',
    'test.ndexbio.org':
      '802839698598-shh458t46bo9v2v5iolcvk1h443hid6n.apps.googleusercontent.com',
    'dev.ndexbio.org':
      '802839698598-mrrd3iq3jl06n6c2fo1pmmc8uugt9ukq.apps.googleusercontent.com'
  }
}

export default config
