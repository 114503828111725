(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("immutable"), require("prop-types"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["immutable", "prop-types", "react"], factory);
	else if(typeof exports === 'object')
		exports["CyTreeViewer"] = factory(require("immutable"), require("prop-types"), require("react"));
	else
		root["CyTreeViewer"] = factory(root["Immutable"], root["PropTypes"], root["React"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_immutable__, __WEBPACK_EXTERNAL_MODULE_prop_types__, __WEBPACK_EXTERNAL_MODULE_react__) {
return 